import { useEffect, useRef, useState } from "react";
import { EventSourceMessage, fetchEventSource } from "@microsoft/fetch-event-source";

import { SolverInterfaceEvent, SolverInterfaceEventType } from "../data/SolverInterfaceEvent";

export type SolverInterfaceEventObserver = (event: SolverInterfaceEvent) => void;
export type SolverInterfaceEventObserverHandle = string;

type SolverInterfaceEventObserverMap = Map<SolverInterfaceEventObserverHandle, SolverInterfaceEventObserverMapEntry>;

type SolverInterfaceEventObserverMapEntry = {
    solverInterfaceEventType: SolverInterfaceEventType;
    observer: SolverInterfaceEventObserver;
};

export enum StreamConnectionStatus {
    CONNECTED,
    CONNECTING,
    DISCONNECTED,
}

const STREAM_CONNECTION_MAX_RETRIES = 5;
const STREAM_CONNECTION_RETRY_INTERVAL_MS = 3000;

class StreamRetryPreventionError extends Error {
    constructor() {
        super();
        this.name = this.constructor.name;
    }
}

export const useStreamConnection = (
    onErrorResponse: (status: number) => void,
    onReconnectionFailed: () => void
): {
    connect: (url: string | undefined) => void;
    streamConnectionStatus: StreamConnectionStatus;
    addSolverInterfaceEventObserver: (
        solverInterfaceEventType: SolverInterfaceEventType,
        observer: SolverInterfaceEventObserver
    ) => SolverInterfaceEventObserverHandle;
    removeSolverInterfaceEventObserver: (handle: SolverInterfaceEventObserverHandle) => void;
} => {
    const [streamConnectionStatus, setStreamConnectionStatus] = useState<StreamConnectionStatus>(
        StreamConnectionStatus.DISCONNECTED
    );

    const [solverInterfaceEventObservers, setSolverInterfaceEventObservers] = useState<SolverInterfaceEventObserverMap>(
        new Map()
    );

    // This ref is given to onMessage() in fetchEventSource(), so it can
    // access the latest eventSourceObservers. Otherwise, they would have the
    // initial value of eventSourceObservers, and would not be able to see
    // updates to the Map.
    // TODO: can we use useSyncExternalStore here?
    const solverInterfaceEventObserversRef = useRef(solverInterfaceEventObservers);
    useEffect(() => {
        solverInterfaceEventObserversRef.current = solverInterfaceEventObservers;
    }, [solverInterfaceEventObservers]);

    const streamConnectionRetries = useRef<number>(0);
    const streamConnectionController = useRef<AbortController | null>(null);

    const connect = (url: string | undefined) => {
        if (streamConnectionController.current) {
            streamConnectionController.current.abort();
        }

        if (!url) {
            setStreamConnectionStatus(StreamConnectionStatus.DISCONNECTED);
            return;
        }

        streamConnectionRetries.current = 0;
        streamConnectionController.current = new AbortController();

        setStreamConnectionStatus(StreamConnectionStatus.CONNECTING);

        initEventSource(url, streamConnectionController.current);
    };

    const initEventSource = (url: string, abortController: AbortController) => {
        // Disconnects the event source when the tab is closed.
        // TODO: we should avoid using beforeunload, as firefox's bfcache
        // implementation will not fire it, which is bad for performance.
        window.onbeforeunload = () => {
            abortController.abort();
        };

        const request = new Request(url, { credentials: "include" });

        fetchEventSource(request, {
            signal: abortController.signal,
            onopen: async (response: Response) => {
                if (abortController.signal.aborted) {
                    return Promise.resolve();
                }

                if (response.status >= 400) {
                    onErrorResponse(response.status);

                    streamConnectionRetries.current = STREAM_CONNECTION_MAX_RETRIES;
                    return;
                }

                streamConnectionRetries.current = 0;

                setStreamConnectionStatus(StreamConnectionStatus.CONNECTED);

                return Promise.resolve();
            },
            onmessage: (event: EventSourceMessage) => {
                if (abortController.signal.aborted) {
                    return;
                }

                if (event.event === SolverInterfaceEventType.DISCONNECT) {
                    connect(url);
                    return;
                }

                // Notify all observers of the event.
                solverInterfaceEventObserversRef.current.forEach(({ solverInterfaceEventType, observer }) => {
                    if (solverInterfaceEventType === event.event) {
                        try {
                            observer(JSON.parse(event.data) as SolverInterfaceEvent);
                        } catch (error) {
                            if (error instanceof SyntaxError) {
                                console.error("Error parsing Solver interface event JSON", event.data);
                            } else {
                                throw error;
                            }
                        }
                    }
                });
            },
            onerror() {
                if (abortController.signal.aborted) {
                    throw new StreamRetryPreventionError();
                }

                streamConnectionRetries.current = streamConnectionRetries.current + 1;
                setStreamConnectionStatus(StreamConnectionStatus.CONNECTING);

                // Throw to prevent fetch-event-source from trying to reconnect
                // https://github.com/Azure/fetch-event-source/blob/45ac3cfffd30b05b79fbf95c21e67d4ef59aa56a/src/fetch.ts#L136
                if (streamConnectionRetries.current >= STREAM_CONNECTION_MAX_RETRIES) {
                    throw new StreamRetryPreventionError();
                }

                return STREAM_CONNECTION_RETRY_INTERVAL_MS;
            },
            onclose() {
                if (abortController.signal.aborted) {
                    return;
                }
                throw new StreamRetryPreventionError();
            },
            // Don't close the connection when this tab does not have focus.
            openWhenHidden: true,
        }).then(
            () => {},
            (error: Error) => {
                // We've exhausted our retries.
                if (error instanceof StreamRetryPreventionError) {
                    onReconnectionFailed();
                    setStreamConnectionStatus(StreamConnectionStatus.DISCONNECTED);
                }
            }
        );
    };

    const addSolverInterfaceEventObserver = (
        solverInterfaceEventType: SolverInterfaceEventType,
        observer: SolverInterfaceEventObserver
    ): SolverInterfaceEventObserverHandle => {
        const handle = Math.random().toString(36).substring(7);

        setSolverInterfaceEventObservers((prevSolverInterfaceEventObservers) => {
            return new Map(prevSolverInterfaceEventObservers.set(handle, { solverInterfaceEventType, observer }));
        });

        return handle;
    };

    const removeSolverInterfaceEventObserver = (handle: SolverInterfaceEventObserverHandle) => {
        setSolverInterfaceEventObservers((prevSolverInterfaceEventObservers) => {
            const newSolverInterfaceEventObservers = new Map(prevSolverInterfaceEventObservers);
            newSolverInterfaceEventObservers.delete(handle);
            return newSolverInterfaceEventObservers;
        });
    };

    return {
        connect,
        streamConnectionStatus,
        addSolverInterfaceEventObserver,
        removeSolverInterfaceEventObserver,
    };
};
