"use client";
import { FilterOutlined, LoadingOutlined, PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Popover, Tooltip, Menu } from "antd";
import { NotificationInstance } from "antd/lib/notification/interface";
import { FixedSizeList, ListChildComponentProps, ListOnScrollProps } from "react-window";
import React, { useEffect, useLayoutEffect, useMemo, useState, useCallback } from "react";
import { AuthorSelectionFilter, useSessionBrowsingContext } from "../data/SessionBrowsing";
import { Session } from "../data/SolverSession";
import { useDebounce } from "../hooks/useDebounce";

import "./SessionsList.css";
import FilterList from "./FilterList";
import SessionCard from "./SessionCard";

import { getBranches, Repo } from "../data/Repos";
import { SessionStatus } from "../data/SolverSession";
import { User } from "../data/User";

import "./SessionsList.css";

const propertyValueAsInt = (property: string) => {
    return parseInt(getComputedStyle(document.documentElement).getPropertyValue(property));
};

interface SessionsListItemData {
    sessions: Session[];
    onSwitchSession: (session: Session) => Promise<void>;
    onUpdateTitle: (session: Session, newName: string) => Promise<boolean>;
    onDeleteSession: (session: Session) => void;
    onCloneSession: (session: Session) => void;
    notification: NotificationInstance;
    currentUser: User | undefined;
}

const SessionsListRow: React.FC<ListChildComponentProps<SessionsListItemData>> = ({ index, style, data }) => {
    const session = data.sessions[index];
    const { onSwitchSession, onUpdateTitle, onDeleteSession, onCloneSession, notification, currentUser } = data;

    return (
        <li
            className="session-list-item"
            key={session.session_id}
            onClick={(e) => {
                // Don't switch if the user is selecting text.
                if (document.getSelection()?.toString() !== "") return;

                onSwitchSession(session);
                e.preventDefault();
            }}
            style={style}
        >
            <SessionCard
                currentUser={currentUser}
                session={session}
                selected={false}
                notification={notification}
                onUpdateTitle={(newName) => onUpdateTitle(session, newName)}
                onDelete={() => onDeleteSession(session)}
                onClone={() => onCloneSession(session)}
                editable={currentUser?.id === session.user_id}
            />
        </li>
    );
};

const SessionsList: React.FC<{
    currentUser: User | undefined;
    repo: Repo;
    activeSession: Session | undefined;
    activeSessionStatus: SessionStatus;
    onNewSession: (branch: string) => Promise<void>;
    onSwitchSession: (session: Session) => Promise<void>;
    onUpdateTitle: (session: Session, newName: string) => Promise<boolean>;
    onDeleteSession: (session: Session) => void;
    onCloneSession: (session: Session) => void;
    notification: NotificationInstance;
}> = ({
    currentUser,
    repo,
    activeSession,
    activeSessionStatus,
    onNewSession,
    onSwitchSession,
    onUpdateTitle,
    onDeleteSession,
    onCloneSession,
    notification,
}) => {
    const [createInProgress, setCreateInProgress] = React.useState<boolean>(false);
    const [branches, setBranches] = React.useState<string[]>([]);
    const [branchFilter, setBranchFilter] = React.useState<string>("");
    const [filteredBranches, setFilteredBranches] = React.useState<string[]>([]);
    const [isNewSessionPopoverOpen, setIsNewSessionPopoverOpen] = React.useState<boolean>(false);
    const [loadingBranches, setLoadingBranches] = React.useState<boolean>(false);

    const {
        sessions,
        loadingSessions,
        page,
        haveMoreSessions,
        titleFilter,
        setTitleFilter,
        authorSelectionFilter,
        setAuthorSelectionFilter,
        loadSessions,
        loadMoreSessions,
    } = useSessionBrowsingContext();

    const debouncedLoadSessions = useDebounce(() => {
        loadSessions(repo.org, repo.name);
    }, 1000);

    useEffect(() => {
        setAuthorSelectionFilter(AuthorSelectionFilter.ALL);
        setLoadingBranches(true);
        getBranches(repo.org, repo.name, 250)
            .then((branches) => {
                setBranches(branches);
                setFilteredBranches(branches);
            })
            .catch(() => {
                notification.error({
                    message: "Failed to load branches",
                    placement: "bottomRight",
                });
            })
            .finally(() => {
                setLoadingBranches(false);
            });
        setBranchFilter("");
    }, [repo]);

    useEffect(() => {
        const filtered = branches.filter((branch) => branch.toLowerCase().includes(branchFilter.toLowerCase()));
        setFilteredBranches(filtered);
    }, [branches, branchFilter]);

    const listableSessions = useMemo(() => {
        return sessions.filter((session: Session) => session.session_id !== activeSession?.session_id);
    }, [sessions, activeSession]);

    const [listHeight, setListHeight] = useState<number>(
        document.body.scrollHeight -
            propertyValueAsInt("--app-header-height") -
            propertyValueAsInt("--sessions-list-header-height")
    );

    useLayoutEffect(() => {
        let height =
            document.body.scrollHeight -
            propertyValueAsInt("--app-header-height") -
            propertyValueAsInt("--sessions-list-header-height");

        if (activeSession) {
            height -= propertyValueAsInt("--active-session-card-height");
            height -= propertyValueAsInt("--sessions-list-divider-height");
        }

        setListHeight(height);
    }, [activeSession]);

    const createNewSession = useCallback(
        async (branch?: string) => {
            if (createInProgress) return;

            const branchToUse = branch || repo.default_branch;
            if (!branchToUse) {
                notification.error({
                    message: "No branch selected",
                    description: "Please select a branch before creating a session.",
                    placement: "bottomRight",
                });
                return;
            }

            setCreateInProgress(true);
            try {
                await onNewSession(branchToUse);
                setIsNewSessionPopoverOpen(false);
                setBranchFilter("");
            } catch (error) {
                notification.error({
                    message: "Failed to create session",
                    description: error instanceof Error ? error.message : "An unknown error occurred",
                    placement: "bottomRight",
                });
            } finally {
                setCreateInProgress(false);
            }
        },
        [createInProgress, onNewSession, repo.default_branch]
    );

    const onScrollList = (scrollProps: ListOnScrollProps) => {
        if (loadingSessions) return;
        if (!haveMoreSessions) return;

        const cardMaxHeight = parseInt(
            getComputedStyle(document.documentElement).getPropertyValue("--inactive-session-card-height")
        );

        const scrollHeight = listableSessions.length * cardMaxHeight;
        const isListScrollable = scrollHeight > listHeight;
        const isFullyScrolled = scrollHeight - scrollProps.scrollOffset <= listHeight;

        if (isListScrollable && isFullyScrolled && scrollProps.scrollDirection === "forward") {
            loadMoreSessions(repo.org, repo.name);
        }
    };

    const buildNewSessionButton = () => {
        return (
            <Popover
                placement="bottomLeft"
                trigger="click"
                content={buildNewSessionList()}
                arrow={false}
                open={isNewSessionPopoverOpen}
                onOpenChange={(open) => setIsNewSessionPopoverOpen(open)}
            >
                <Tooltip title="Create new session" arrow={false} placement="right">
                    <Button icon={<PlusCircleOutlined />} loading={createInProgress} />
                </Tooltip>
            </Popover>
        );
    };

    const buildNewSessionList = () => {
        const handleMenuClick = ({ key }: { key: string }) => {
            if (key !== "no-results") {
                createNewSession(key);
            }
        };

        if (loadingBranches) {
            return (
                <div className="new-session-loading-branches">
                    Loading branches...
                    <LoadingOutlined />
                </div>
            );
        }

        return (
            <div className="new-session-dropdown-wrapper">
                <div className="new-session-instructions">
                    Select a branch to use as the base revision of the new session
                </div>
                <Input
                    className="new-session-dropdown-input"
                    placeholder="Search branches"
                    value={branchFilter}
                    addonBefore={<SearchOutlined />}
                    onChange={(e) => setBranchFilter(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && filteredBranches.length === 1) {
                            createNewSession(filteredBranches[0]);
                        }
                    }}
                />
                <Menu
                    onClick={handleMenuClick}
                    className="new-session-dropdown-menu scrollbar scrollbar-gutter-stable-both"
                    selectable={false}
                >
                    {filteredBranches.length === 0 ? (
                        <Menu.Item key="no-results" disabled>
                            No matching branches
                        </Menu.Item>
                    ) : (
                        <>
                            {repo.default_branch && filteredBranches.includes(repo.default_branch) && (
                                <>
                                    <Menu.Item key={repo.default_branch}>
                                        <div className="new-session-dropdown-menu-item-inner">
                                            <div>{repo.default_branch}</div>
                                            <div className="new-session-default-branch-badge">default</div>
                                        </div>
                                    </Menu.Item>
                                    <Menu.Divider />
                                </>
                            )}
                            {filteredBranches
                                .filter((branch) => branch !== repo.default_branch)
                                .map((branch) => (
                                    <Menu.Item key={branch}>
                                        <div className="new-session-dropdown-menu-item-inner">
                                            <div>{branch}</div>
                                        </div>
                                    </Menu.Item>
                                ))}
                        </>
                    )}
                </Menu>
            </div>
        );
    };

    const buildActiveSessionCard = () => {
        if (!activeSession) return null;

        activeSession.status = activeSessionStatus;

        return (
            <>
                <div className="active-session-card-wrapper">
                    <SessionCard
                        currentUser={currentUser}
                        session={activeSession}
                        selected={true}
                        notification={notification}
                        onUpdateTitle={(newName) => onUpdateTitle(activeSession, newName)}
                        onDelete={() => onDeleteSession(activeSession)}
                        onClone={() => onCloneSession(activeSession)}
                        editable={currentUser?.id === activeSession.user_id}
                    />
                </div>
                <Divider type={"horizontal"} className="session-list-divider" />
            </>
        );
    };

    const buildList = () => {
        if (loadingSessions && page < 1) {
            return (
                <div style={{ textAlign: "center" }}>
                    <LoadingOutlined />
                </div>
            );
        } else if (sessions.length === 0) {
            return <div style={{ textAlign: "center" }}>No sessions found</div>;
        }

        const listData: SessionsListItemData = {
            sessions: listableSessions,
            onSwitchSession,
            onUpdateTitle,
            onDeleteSession,
            onCloneSession,
            notification,
            currentUser,
        };

        return (
            <FixedSizeList
                className="scrollbar scrollbar-gutter-stable"
                height={listHeight}
                itemCount={listableSessions.length}
                itemSize={propertyValueAsInt("--inactive-session-card-height")}
                itemData={listData}
                width="100%"
                onScroll={onScrollList}
            >
                {SessionsListRow}
            </FixedSizeList>
        );
    };

    const buildFilterList = () => {
        return <div className="session-filter-list">{buildFilterListContent()}</div>;
    };

    const buildFilterListContent = () => {
        return (
            <FilterList
                items={[
                    { key: AuthorSelectionFilter.ALL, label: "All Sessions" },
                    { key: AuthorSelectionFilter.MINE, label: "My Sessions" },
                ]}
                selectedKeys={[authorSelectionFilter]}
                onSelect={(key) => {
                    setAuthorSelectionFilter(key as AuthorSelectionFilter);

                    debouncedLoadSessions();
                }}
                buttonStyle="radio"
            />
        );
    };

    return (
        <>
            <div className="session-list-header">
                <Input
                    value={titleFilter}
                    onChange={(e) => {
                        setTitleFilter(e.target.value);
                        debouncedLoadSessions();
                    }}
                    placeholder="Search sessions"
                    addonBefore={<SearchOutlined />}
                    addonAfter={
                        <Popover arrow={false} trigger={"click"} content={buildFilterList()} placement="bottomRight">
                            <Tooltip title="Filter" arrow={false} placement="right">
                                <Button type="text" size="small" icon={<FilterOutlined />} />
                            </Tooltip>
                        </Popover>
                    }
                    allowClear
                />
                {buildNewSessionButton()}
            </div>
            {buildActiveSessionCard()}
            {buildList()}
        </>
    );
};

export default SessionsList;
