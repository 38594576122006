import { solverInterfaceApiAxios } from "./SolverInterfaceConstants";

import React, { createContext, useEffect, useState } from "react";

import { useSolverInterfaceContext } from "./SolverInterface";

interface DockerExecutionSettings {
    docker_registry_url?: string;
    docker_image?: string;
    docker_tag?: string;
    docker_username?: string;
    docker_password?: string;
}

export type ExecutionSettingsContextType = {
    dockerExecutionSettings: DockerExecutionSettings | undefined;
    setExecutionSettings: (settings: DockerExecutionSettings) => Promise<void>;
    resetExecutionSettings: () => Promise<void>;
    error: string | undefined;
    loading: boolean;
};

const ExecutionSettingsContext = createContext<ExecutionSettingsContextType | undefined>(undefined);

const ExecutionSettingsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { activeRepo } = useSolverInterfaceContext();
    const [dockerExecutionSettings, setDockerExecutionSettings] = useState<DockerExecutionSettings | undefined>(
        undefined
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        const refreshExecutionSettings = async () => {
            if (!activeRepo) {
                setDockerExecutionSettings(undefined);
            } else {
                setLoading(true);
                const getExecutionSettingsResult = await getExecutionSettings(activeRepo.org, activeRepo.name);
                setDockerExecutionSettings(getExecutionSettingsResult.dockerExecutionSettings);
                setError(getExecutionSettingsResult.error ? "Failed to load execution settings" : undefined);
                setLoading(false);
            }
        };

        refreshExecutionSettings();
    }, [activeRepo]);

    const setExecutionSettings = async (newDockerExecutionSettings: DockerExecutionSettings) => {
        if (!activeRepo) {
            console.warn("No active repo");
            return Promise.resolve();
        }

        if (await postExecutionSettings(activeRepo.org, activeRepo.name, newDockerExecutionSettings)) {
            setDockerExecutionSettings((prevDockerExecutionSettings) => {
                return { ...prevDockerExecutionSettings, ...newDockerExecutionSettings };
            });
            setError(undefined);
        } else {
            setError("Failed to save execution settings");
        }
    };

    const resetExecutionSettings = async () => {
        if (!activeRepo) {
            console.warn("No active repo");
            return Promise.resolve();
        }

        if (await deleteExecutionSettings(activeRepo.org, activeRepo.name)) {
            setDockerExecutionSettings(undefined);
            setError(undefined);
        } else {
            setError("Failed to delete execution settings");
        }
    };

    return (
        <ExecutionSettingsContext.Provider
            value={{ dockerExecutionSettings, setExecutionSettings, resetExecutionSettings, error, loading }}
        >
            {children}
        </ExecutionSettingsContext.Provider>
    );
};

const useExecutionSettingsContext = () => {
    const ctx = React.useContext(ExecutionSettingsContext);
    if (!ctx) {
        throw new Error("useExecutionSettingsContext must be used within a ExecutionSettingsContextProvider");
    }
    return ctx;
};

type GetExecutionSettingsResult = {
    dockerExecutionSettings: DockerExecutionSettings;
    error: boolean;
};

const getExecutionSettings = async (org: string, repo: string): Promise<GetExecutionSettingsResult> => {
    return await solverInterfaceApiAxios
        .get<DockerExecutionSettings>(`${org}/${repo}/execution_settings`)
        .then((response) => {
            return { dockerExecutionSettings: response.data, error: false };
        })
        .catch((error) => {
            console.log(error);
            return { dockerExecutionSettings: {}, error: true };
        });
};

const postExecutionSettings = async (
    org: string,
    repo: string,
    dockerExecutionSettings: DockerExecutionSettings
): Promise<boolean> => {
    try {
        await solverInterfaceApiAxios.post(`${org}/${repo}/execution_settings`, dockerExecutionSettings);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

const deleteExecutionSettings = async (org: string, repo: string): Promise<boolean> => {
    try {
        await solverInterfaceApiAxios.delete(`${org}/${repo}/execution_settings`);
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export { ExecutionSettingsProvider, useExecutionSettingsContext };

export type { DockerExecutionSettings };
