import React, { useEffect } from "react";
import { App, Layout } from "antd";

import SessionsSider from "./components/SessionsSider";
import ActiveSession from "./components/ActiveSession";
import SolverHeader from "./components/SolverHeader";
import Startup from "./components/Startup";
import { ConnectToSolverInterfaceArgs, useSolverInterfaceContext } from "./data/SolverInterface";
import { useLoadSession } from "./data/SolverSession";
import { AppPath, NavigationBehavior, parseAppPath } from "./data/Navigation";

const SolverApp: React.FC = () => {
    const { loggedIn, connectToSolverInterface, onSignUpTokenInvalid } = useSolverInterfaceContext();
    const loadSession = useLoadSession();

    useEffect(() => {
        const initApp = async () => {
            const pathConstituents: AppPath = parseAppPath(document.location.pathname, document.location.search);
            const signUpToken = pathConstituents.signUpToken;

            if (pathConstituents.signUpTokenFailure) {
                onSignUpTokenInvalid();
            } else if (pathConstituents.repo) {
                const [org, repo] = pathConstituents.repo.split("/");

                const connectedSuccessfully = await connectToSolverInterface({
                    requestedOrg: org,
                    requestedRepo: repo,
                    signUpToken,
                });

                if (connectedSuccessfully && pathConstituents.session) {
                    loadSession({ org, repo, session_id: pathConstituents.session }, NavigationBehavior.NONE);
                }
            } else {
                await connectToSolverInterface({ signUpToken });
            }
        };

        initApp();
    }, []);

    const buildMainLayout = () => {
        if (!loggedIn) {
            return <Startup />;
        } else {
            return (
                <Layout>
                    <SessionsSider />
                    <ActiveSession />
                </Layout>
            );
        }
    };

    return (
        <App>
            <SolverHeader />
            {buildMainLayout()}
        </App>
    );
};

export default SolverApp;
