import { FunctionOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { NotificationInstance } from "antd/lib/notification/interface";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import { solverInterfaceApiAxios } from "../data/SolverInterfaceConstants";
import "./Conversation.css";
import DetailEditor, { FocusableRef } from "./DetailEditor";

import {
    SessionStatus,
    useCanCancelSolve,
    useCanSolve,
    useCancelSolve,
    useCreateAndSolve,
    useSession,
    useSessionNLDraftDetail,
    useSessionStatus,
    useSolve,
} from "../data/SolverSession";

import { useSolverInterfaceContext } from "../data/SolverInterface";

const CustomAudioRecorder: React.FC<{ onRecordingComplete: (blob: Blob) => void; disabled: boolean }> = ({
    onRecordingComplete,
    disabled,
}) => {
    return (
        <div className={`custom-audio-recorder ${disabled ? "disabled" : ""}`}>
            {!disabled && (
                <AudioRecorder
                    onRecordingComplete={onRecordingComplete}
                    audioTrackConstraints={{
                        noiseSuppression: true,
                        echoCancellation: true,
                    }}
                    downloadOnSavePress={false}
                    downloadFileExtension="wav"
                    showVisualizer={true}
                    classes={{
                        AudioRecorderClass: "audio-recorder",
                        AudioRecorderStartSaveClass: "audio-recorder-start-save custom-submit-button",
                        AudioRecorderPauseResumeClass: "display-none",
                        AudioRecorderDiscardClass: "audio-recorder-discard",
                    }}
                />
            )}
        </div>
    );
};
const NaturalLanguageInput: React.FC<{ notification: NotificationInstance }> = ({ notification }) => {
    const session = useSession();
    const nlDraftDetail = useSessionNLDraftDetail();
    const sessionStatus = useSessionStatus();
    const solve = useSolve();
    const createAndSolve = useCreateAndSolve();
    const canSolve = useCanSolve();
    const cancelSolve = useCancelSolve();
    const canCancelSolve = useCanCancelSolve();

    const { currentUser, activeRepo } = useSolverInterfaceContext();

    const detailEditorRef = useRef<FocusableRef>(null);

    // Cache the title and detail so that they can be updated when we solve.
    const [cachedNLDraftDetail, setCachedNLDraftDetail] = useState<string>("");
    const [isTranscribing, setIsTranscribing] = useState<boolean>(false);

    useEffect(() => {
        setCachedNLDraftDetail(nlDraftDetail);
    }, [nlDraftDetail]);

    useEffect(() => {
        detailEditorRef.current?.focus();
    }, [session?.session_id]);

    const onDetailChange = (value: string | undefined) => setCachedNLDraftDetail(value || "");

    const handleAudioStop = async (blob: Blob) => {
        setIsTranscribing(true);
        try {
            const formData = new FormData();
            formData.append("audio", blob, "audio.wav");
            const response = await solverInterfaceApiAxios.post("/transcribe", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.data && response.data.transcription) {
                const transcribedText = response.data.transcription;
                setCachedNLDraftDetail(transcribedText);
                // Automatically submit the transcribed text
                if (!session) {
                    if (activeRepo) {
                        createAndSolve(transcribedText, activeRepo.org, activeRepo.name, activeRepo.default_branch);
                    } else {
                        throw new Error("No active repository selected");
                    }
                } else {
                    solve(transcribedText);
                }
            } else {
                throw new Error("Transcription data is missing or invalid");
            }
        } catch (error: unknown) {
            console.error("Error during transcription:", error);
            let errorMessage = "An error occurred while transcribing the audio.";
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    errorMessage = `Server error: ${error.response.status}`;
                } else if (error.request) {
                    errorMessage = "No response received from the server.";
                } else {
                    errorMessage = error.message || "Unknown error occurred";
                }
            } else if (error instanceof Error) {
                errorMessage = error.message;
            }
            notification.error({
                message: "Transcription failed",
                description: errorMessage,
                placement: "bottomRight",
            });
        } finally {
            setIsTranscribing(false);
        }
    };

    const buildButton = () => {
        switch (sessionStatus) {
            case SessionStatus.READY:
                return buildSolveButton();
            case SessionStatus.SUBMITTING_SOLVE:
                return buildSubmittingSolveButton();
            case SessionStatus.SOLVING:
            case SessionStatus.PENDING:
                return buildCancelSolveButton();
            case SessionStatus.SUBMITTING_CANCEL:
                return buildCancellingSolveButton();
            case SessionStatus.ARCHIVED:
                return buildArchivedButton();
            default:
                return buildSolveButton();
        }
    };

    const buildSolveButton = () => {
        return (
            <Button
                className="solve-button"
                type="primary"
                onClick={(e) => {
                    onSolve();
                    e.stopPropagation();
                }}
                disabled={!canSolve(cachedNLDraftDetail)}
            >
                <FunctionOutlined /> Solve
            </Button>
        );
    };

    const buildSubmittingSolveButton = () => {
        return (
            <Button className="solve-button" type="primary" disabled={true}>
                <LoadingOutlined /> Submitting
            </Button>
        );
    };

    const buildCancelSolveButton = () => {
        return (
            <Button
                className="solve-button"
                onClick={(e) => {
                    onCancelSolve();
                    e.stopPropagation();
                }}
                disabled={!canCancelSolve()}
            >
                <LoadingOutlined /> Cancel
            </Button>
        );
    };

    const buildCancellingSolveButton = () => {
        return (
            <Button className="solve-button" disabled={true}>
                <LoadingOutlined /> Cancelling
            </Button>
        );
    };

    const buildArchivedButton = () => {
        return (
            <Tooltip title="This session has been archived and can no longer be solved.">
                <Button className="solve-button" disabled={true}>
                    Archived
                </Button>
            </Tooltip>
        );
    };

    const onSolve = () => {
        if (!currentUser || !activeRepo) return;

        if (!session) {
            createAndSolve(cachedNLDraftDetail, activeRepo.org, activeRepo.name, activeRepo.default_branch);
        } else {
            solve(cachedNLDraftDetail);
        }
    };

    const onCancelSolve = () => {
        if (!canCancelSolve()) return;

        cancelSolve();
    };

    const onDetailEditorKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (processSolveHotkey(e)) return;
    };

    const processSolveHotkey = (e: React.KeyboardEvent<HTMLElement>): boolean => {
        if ((e.ctrlKey || e.metaKey) && e.key === "Enter" && canSolve(cachedNLDraftDetail)) {
            onSolve();
            (e.target as HTMLTextAreaElement).blur();
            e.preventDefault();
            return true;
        }

        return false;
    };

    return (
        <div
            className="nl-text-container"
            onClick={() => {
                detailEditorRef.current?.focus();
            }}
        >
            <DetailEditor
                ref={detailEditorRef}
                value={cachedNLDraftDetail}
                onChange={onDetailChange}
                onKeyDown={onDetailEditorKeyDown}
                placeholder="Write a task, pose a problem, or ask a question"
                disabled={session?.is_read_only}
            />
            <div className="solve-button-container">
                <div className={`audio-recorder-container ${isTranscribing ? "transcribing" : ""}`}>
                    <CustomAudioRecorder
                        onRecordingComplete={handleAudioStop}
                        disabled={session?.is_read_only || isTranscribing}
                    />
                    {isTranscribing && <LoadingOutlined className="transcribing-loader" />}
                </div>
                {buildButton()}
            </div>
        </div>
    );
};

export default NaturalLanguageInput;
