"use client";
import React, { useEffect, useState } from "react";

import { CheckOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Tooltip, Typography } from "antd";

import { DockerExecutionSettings, useExecutionSettingsContext } from "../data/ExecutionSettings";

interface ExecutionSettingsFormProps {
    org: string;
    repo: string;
    allowChanges: boolean;
}

interface ExecutionSettingsFormValues {
    registry_url: string;
    image: string;
    tag: string;
    username: string;
    password: string;
}

const formValuesFromExecutionSettings = (settings: DockerExecutionSettings): ExecutionSettingsFormValues => {
    return {
        registry_url: settings.docker_registry_url || "",
        image: settings.docker_image || "",
        tag: settings.docker_tag || "",
        username: settings.docker_username || "",
        password: settings.docker_password || "",
    };
};

const ExecutionSettingsForm: React.FC<ExecutionSettingsFormProps> = ({ org, repo, allowChanges }) => {
    const { dockerExecutionSettings, setExecutionSettings, resetExecutionSettings, error, loading } =
        useExecutionSettingsContext();

    const [editingKeys, setEditingKeys] = useState<Set<string>>(new Set<string>());
    const [submittingNewSettings, setSubmittingNewSettings] = useState<boolean>(false);
    const [deletingSettings, setDeletingSettings] = useState<boolean>(false);

    const [form] = Form.useForm<ExecutionSettingsFormValues>();

    const settingsAreNew = !dockerExecutionSettings || Object.keys(dockerExecutionSettings).length === 0;

    const onBeginEdit = (key: string) => {
        toggleEditing(key);
    };

    const onFinishEdit = (key: string) => {
        const existingValues = dockerExecutionSettings as { [key: string]: string };
        if (form.getFieldValue(key) !== existingValues[`docker_${key}`]) {
            setExecutionSettings({
                [`docker_${key}`]: form.getFieldValue(key),
            });
        }

        toggleEditing(key);
    };

    const toggleEditing = (key: string) => {
        setEditingKeys((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(key)) {
                newSet.delete(key);
            } else {
                newSet.add(key);
            }
            return newSet;
        });
    };

    const deleteSettings = async () => {
        setDeletingSettings(true);
        await resetExecutionSettings();
        setDeletingSettings(false);
    };

    useEffect(() => {
        if (dockerExecutionSettings) {
            const formValues = formValuesFromExecutionSettings(dockerExecutionSettings);
            form.setFieldsValue(formValues);
        } else {
            form.setFieldsValue({
                registry_url: "",
                image: "",
                tag: "",
                username: "",
                password: "",
            });
        }
    }, [dockerExecutionSettings, form]);

    const onFinishForm = (values: ExecutionSettingsFormValues) => {
        setSubmittingNewSettings(true);
        setExecutionSettings({
            docker_registry_url: values.registry_url,
            docker_image: values.image,
            docker_tag: values.tag,
            docker_username: values.username,
            docker_password: values.password,
        });
        setSubmittingNewSettings(false);
    };

    const buildForm = () => {
        return (
            <Form
                className="execution-settings-form"
                disabled={loading || !allowChanges}
                form={form}
                labelCol={{
                    span: 8,
                }}
                labelAlign="left"
                colon={false}
                requiredMark={false}
                onFinish={onFinishForm}
                autoComplete="off"
            >
                {buildFormItem(
                    "registry_url",
                    "Registry URL",
                    false,
                    settingsAreNew || editingKeys.has("registry_url"),
                    false,
                    "The registry where the image is hosted. Example: hub.docker.com."
                )}
                {buildFormItem(
                    "image",
                    "Image",
                    false,
                    settingsAreNew || editingKeys.has("image"),
                    true,
                    "The name of the image to use for execution."
                )}
                {buildFormItem(
                    "tag",
                    "Tag",
                    false,
                    editingKeys.has("tag"),
                    true,
                    "A tag of the image. If not provided, the latest tag will be used."
                )}
                {settingsAreNew &&
                    buildFormItem("username", "Username", false, settingsAreNew || editingKeys.has("username"), false)}
                {settingsAreNew &&
                    buildFormItem(
                        "password",
                        "Access Token",
                        true,
                        settingsAreNew || editingKeys.has("password"),
                        false,
                        "The access token to use for authentication. Consult the host's documentation for information on how to generate an access token."
                    )}
                {settingsAreNew && (
                    <Form.Item
                        wrapperCol={{
                            offset: 0,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit" loading={submittingNewSettings}>
                            Submit
                        </Button>
                    </Form.Item>
                )}
            </Form>
        );
    };

    const buildFormItem = (
        key: string,
        label: string,
        password: boolean,
        required: boolean,
        editable: boolean,
        tooltip?: string
    ) => {
        const inputAddon = !settingsAreNew && editable && allowChanges ? buildInputAddon(key) : null;

        const inputDisabled = !allowChanges || (!settingsAreNew && !editingKeys.has(key));

        return (
            <Form.Item
                className="execution-settings-form-item"
                label={label}
                name={key}
                tooltip={tooltip}
                rules={[
                    {
                        required,
                        message: `${label} is required`,
                    },
                ]}
            >
                {password ? (
                    <Input.Password disabled={inputDisabled} addonAfter={inputAddon} />
                ) : (
                    <Input disabled={inputDisabled} addonAfter={inputAddon} />
                )}
            </Form.Item>
        );
    };

    const buildInputAddon = (key: string) => {
        if (editingKeys.has(key)) {
            return <CheckOutlined onClick={() => onFinishEdit(key)} />;
        } else {
            return <EditOutlined onClick={() => onBeginEdit(key)} />;
        }
    };

    const buildInstructions = () => {
        if (!allowChanges) {
            return (
                <p className="settings-instructions">
                    Execution settings are locked for {org}/{repo}. Contact the repository owner to make changes.
                </p>
            );
        }

        return (
            <p className="settings-instructions">
                {dockerExecutionSettings ? "Edit the" : "Set a"} Docker image for execution during sessions in {org}/
                {repo}
            </p>
        );
    };

    if (loading) {
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <LoadingOutlined />
            </div>
        );
    }

    return (
        <>
            {buildInstructions()}
            {error && <Typography.Text type="danger">{error}</Typography.Text>}
            {buildForm()}
            {dockerExecutionSettings && allowChanges && (
                <Tooltip title="">
                    <Button onClick={deleteSettings} loading={deletingSettings}>
                        Delete execution settings
                    </Button>
                </Tooltip>
            )}
        </>
    );
};

export default ExecutionSettingsForm;
